import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { onPageNavigationEvent } from 'src/lib/analytics';
import creatorByline from 'src/lib/utilities/feature/creatorByline';

import styles from './Headshot.module.scss';

const Headshot = ({
  avatarUrl,
  featureName,
  featureShortName,
  featureCategory,
  creators,
  size,
  onCard,
  bylineSpacing,
  decorateHover,
  clickLocation,
  bylineOverride,
}) => (
  <div
    className={classNames(styles.headshot, {
      [styles[`headshot_${size}Card`]]: onCard,
    })}
  >
    <img
      className={classNames(
        styles.headshot__image,
        styles[`headshot__image_${size}`],
        { [styles[`headshot__image_${size}Card`]]: onCard }
      )}
      src={avatarUrl}
      alt={featureName}
    />
    <div
      className={classNames(styles.headshot__featureInfo, {
        [styles.headshot__featureInfo_card]: onCard,
        [styles.headshot__featureInfo_withSpacing]: bylineSpacing,
      })}
    >
      <Link href={`/${featureCategory}/${featureShortName}`}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <a
          className={classNames(styles.headshot__featureLink, {
            [styles[`headshot__featureLink_${featureCategory}`]]: decorateHover,
          })}
          onClick={
            clickLocation
              ? () => onPageNavigationEvent(clickLocation, 'link', featureName)
              : undefined
          }
          onKeyUp={() =>
            clickLocation
              ? () => onPageNavigationEvent(clickLocation, 'link', featureName)
              : undefined
          }
        >
          {featureName}
        </a>
      </Link>
      <div>{`by ${bylineOverride || creatorByline(creators)}`}</div>
    </div>
  </div>
);

Headshot.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  featureName: PropTypes.string.isRequired,
  featureShortName: PropTypes.string.isRequired,
  featureCategory: PropTypes.string.isRequired,
  creators: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
  onCard: PropTypes.bool,
  bylineSpacing: PropTypes.bool,
  decorateHover: PropTypes.bool,
  clickLocation: PropTypes.string,
  bylineOverride: PropTypes.string,
};

Headshot.defaultProps = {
  onCard: false,
  bylineSpacing: false,
  decorateHover: false,
  clickLocation: '',
  bylineOverride: null,
};

export default Headshot;
